// @ts-nocheck -- this file overlaps JSX with TSX, hence the TS nocheck here as intrinsic errors are definitely going to occur

import * as React from "react";
import { FC, SetStateAction, useContext, useEffect, useState } from "react";
import { UserContext } from "../UserContext";
import { Router, useLocation } from "@reach/router";
import GlobalSideNav from "./GlobalSideNav";
import loadable from "@loadable/component";
import "./styles.scss";

import FlexibleSidebarContainer from "../FlexibleSidebarContainer";

const HomePage = loadable(
  () => import(/* webpackChunkName: "HomePage" */ "../HomePage/HomePage")
);
const UpdatesPage = loadable(
  () =>
    import(/* webpackChunkName: "UpdatesPage" */ "../UpdatesPage/UpdatesPage")
);
const ContactsPage = loadable(
  () =>
    import(
      /* webpackChunkName: "ContactsPage" */ "../ContactsPage/ContactsPage"
    )
);
const ComposerPage = loadable(
  () =>
    import(
      /* webpackChunkName: "ComposerPage" */ "../ComposerPage/ComposerPage"
    )
);
const CreateEditCompany = loadable(
  () =>
    import(
      /* webpackChunkName: "CreateEditCompany" */ "../CreateEditCompany/CreateEditCompany"
    )
);
const PortfolioAdminPage = loadable(
  () =>
    import(
      /* webpackChunkName: "PortfolioAdminPage" */ "../PortfolioAdminPage/PortfolioAdminPage"
    )
);
const CompanyDetailPage = loadable(
  () =>
    import(
      /* webpackChunkName: "CompanyDetailPage" */ "../CompanyDetailPage/CompanyDetailPage"
    )
);

const App: FC = ({ premiumStripePriceId, stripePublicKey }: any) => {
  const [userContext] = useContext(UserContext);

  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);

  const [activePage, setActivePage]: [string | null, SetStateAction<any>] =
    useState(null);

  const location = useLocation();

  useEffect(() => {
    // if (!userContext.focusedCompany) {
    //   return;
    // }

    const pathArray = location.pathname.split("/");

    const expectedPath1 = {
      home: "home",
      portfolios: "portfolios",
      // [userContext.focusedCompany.name]: "profile",
    };

    if (userContext.focusedCompany && userContext.focusedCompany.name) {
      expectedPath1[userContext.focusedCompany.name] = "profile";
    }

    const expectedPath2 = {
      updates: "updates",
      contacts: "contacts",
      composer: "composer",
    };

    if (
      Object.keys(expectedPath1).includes(pathArray[1]) &&
      !pathArray.includes("updates") &&
      !pathArray.includes("contacts") &&
      !pathArray.includes("composer")
    ) {
      setActivePage(expectedPath1[pathArray[1]]);
    } else if (
      pathArray.length > 2 &&
      Object.keys(expectedPath2).includes(pathArray[2])
    ) {
      setActivePage(expectedPath2[pathArray[2]]);
    } else {
      setActivePage(null);
    }
  }, [userContext.focusedCompany, location.pathname]);

  useEffect(() => {
    if (
      activePage === "updates" ||
      activePage === "contacts" ||
      activePage === "composer"
    ) {
      setIsSidebarExpanded(false);
    }
  }, [activePage]);

  // if (!userContext.user) {
  //   return null;
  // }

  // if (!userContext.focusedCompany) {
  //   return null;
  // }

  useEffect(() => {
    if (!window) {
      return;
    }

    const { innerWidth: width, innerHeight: height } = window;

    // console.log("width :>> ", width);
    if (width < 576) {
      setIsSidebarExpanded(false);
    }
  }, []);

  return (
    <>
      {userContext.loaded === true && (
        <div>
          <FlexibleSidebarContainer
            sidebarLocation="left"
            className={`ps-global-side-nav__flexible-sidebar-nav-container ${
              isSidebarExpanded === false
                ? "ps-global-side-nav__flexible-sidebar-nav-container--collapsed"
                : ""
            }`}
            left={
              <GlobalSideNav
                activePage={activePage}
                setActivePage={setActivePage}
                isSidebarExpanded={isSidebarExpanded}
                setIsSidebarExpanded={setIsSidebarExpanded}
              />
            }
            right={
              <>
                <Router basepath={"/"}>
                  <HomePage path={"home"} />
                  <UpdatesPage
                    path={`:companyName/updates/*`}
                    userId={userContext.user ? userContext.user.user_id : null}
                    isGlobalSidebarExpanded={isSidebarExpanded}
                  />
                  <CompanyDetailPage
                    key={location.pathname}
                    path={`:companyName/*`}
                    // company={userContext.focusedCompany}
                  />
                  <CreateEditCompany
                    path={`:companyName/edit/*`}
                    isSuperAdmin={
                      userContext.user ? userContext.user.is_super_admin : false
                    }
                    premiumStripePriceId={premiumStripePriceId}
                    stripePublicKey={stripePublicKey}
                  />
                  <ContactsPage path={`:companyName/contacts/*`} />
                  {[
                    ":companyName/composer/*",
                    ":companyName/composer/:postId/*",
                  ].map((page) => (
                    <ComposerPage
                      key={location.pathname}
                      // path={`:companyName/composer/:postId/*`}
                      path={page}
                    />
                  ))}
                  {["portfolios", "portfolios/:portfolioId/*"].map(
                    (page, index) => (
                      <PortfolioAdminPage
                        key={index}
                        path={page}
                        userId={
                          userContext.user ? userContext.user.user_id : null
                        }
                      />
                    )
                  )}
                </Router>
              </>
            }
          />
        </div>
      )}
    </>
  );
};

export default App;
