import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./styles.scss";

export const ToastAlertContainer = () => {
  return <ToastContainer position={toast.POSITION.TOP_CENTER} hideProgressBar={true} />;
};

ToastAlertContainer.propTypes = {};

export { toast };
