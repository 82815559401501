/* eslint-disable react/display-name */
import React, { useState, useContext, useRef } from "react";
import PropTypes from "prop-types";
import { UserContext } from "UserContext";
import { OverlayTrigger, Dropdown, Tooltip } from "react-bootstrap";
import "./styles.scss";

const SelectSearchDropdown = ({
  descriptionTooltipText = "Select a company you're an admin of to navigate Paperstreet on it's behalf.",
  companyDisplayName,
  companyLogoUrl,
  dropdownEnabled = true,
  onCompanySelect,
  companies,
}) => {
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        if (dropdownEnabled) {
          e.preventDefault();
          onClick(e);
        }
      }}
      className={`ps-select-search-dropdown__focused-company-text-logo-container ${
        dropdownEnabled === true
          ? "ps-select-search-dropdown__focused-company-text-logo-container--enable-dropdown"
          : ""
      }`}
    >
      <img
        className="ps-select-search-dropdown__focused-company-logo"
        src={companyLogoUrl}
      />
      <div className="ps-select-search-dropdown__focused-company-name-text">
        {companyDisplayName}
      </div>
    </div>
  ));

  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={`${className} ps-select-search-dropdown__dropdown-container`}
          aria-labelledby={labeledBy}
        >
          <div className="ps-select-search-dropdown__search-info-container">
            <div className="ps-select-search-dropdown__search-bar">
              <input
                className="ps-select-search-dropdown__search-input"
                placeholder="Search your companies..."
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
              <img
                src="/images/icons/search-icon.png"
                className="ps-select-search-dropdown__search-icon"
              />
            </div>
            <OverlayTrigger
              overlay={<Tooltip>{descriptionTooltipText}</Tooltip>}
            >
              <img
                src="/images/icons/info.png"
                className="ps-select-search-dropdown__search-info"
              />
            </OverlayTrigger>
          </div>
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter((child) => {
              if (!value) {
                return true;
              }
              if (!child.props.companydisplayname) {
                return false;
              }

              if (
                !child.props.companydisplayname.toLowerCase().includes(value)
              ) {
                return false;
              }

              return true;
            })}
          </ul>
        </div>
      );
    }
  );

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        {companyDisplayName}
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu}>
        {companies.map((company, index) => (
          <Dropdown.Item
            key={index}
            companydisplayname={company.display_name}
            className="ps-select-search-dropdown__list-item-container"
            onClick={() => {
              onCompanySelect(company);
            }}
          >
            <img
              className="ps-select-search-dropdown__list-item-logo"
              src={company.logo_url}
            />
            <div className="ps-select-search-dropdown__list-item-text">
              {company.display_name}
            </div>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

SelectSearchDropdown.propTypes = {
  setIsOpen: PropTypes.func,
};

export default SelectSearchDropdown;
