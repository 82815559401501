// import App from "./App";

// export default App;

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { QueryClient, QueryClientProvider } from "react-query";
import { UserProvider } from "UserContext/UserContext.jsx";
import { ConfirmationServiceProvider } from "ConfirmationDialog/ConfirmationService";
import { ToastAlertContainer } from "ToastAlertContainer";
import { LocationProvider, createHistory } from "@reach/router";

let appContainer = document.getElementById("app-page-container");

let premiumStripePriceId = appContainer.getAttribute(
  "data-premium-stripe-price-id"
);

let stripePublicKey = appContainer.getAttribute("data-stripe-public-key");

let userId = appContainer.getAttribute("data-user-id");

let history = createHistory(window);

const queryClient = new QueryClient({
  refetchOnWindowFocus: false,
  staleTime: 300000
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <LocationProvider history={history}>
      <UserProvider userId={userId}>
        <ConfirmationServiceProvider>
          <App
            premiumStripePriceId={premiumStripePriceId}
            stripePublicKey={stripePublicKey}
          />
        </ConfirmationServiceProvider>
      </UserProvider>
    </LocationProvider>
    <ToastAlertContainer />
  </QueryClientProvider>,
  appContainer
);
