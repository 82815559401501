import React from "react";
import PropTypes from "prop-types";

import "FlexibleSidebarContainer/styles.scss";

const FlexibleSidebarContainer = (props) => {
  return (
    <React.Fragment>
      {props.sidebarLocation === "left" && (
        <div
          className={`ps-flexible-sidebar__container ${
            props.className ? props.className : ""
          }`}
        >
          <div
            className="ps-flexible-sidebar__sidebar-container"
            style={{
              width: props.sidebarWidth,
              transition: "all 0.2s ease-in-out",
              WebkitTransition: "all 0.2s ease-in-out",
            }}
          >
            {props.left}
          </div>
          <div className="ps-flexible-sidebar__flex-container">
            {props.right}
          </div>
        </div>
      )}

      {props.sidebarLocation === "right" && (
        <div className="ps-flexible-sidebar__container">
          <div className="ps-flexible-sidebar__flex-container">
            {props.left}
          </div>
          <div
            className="ps-flexible-sidebar__sidebar-container"
            style={{ width: props.sidebarWidth }}
          >
            {props.right}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default FlexibleSidebarContainer;

FlexibleSidebarContainer.propTypes = {
  sidebarLocation: PropTypes.string,
  left: PropTypes.element,
  right: PropTypes.element,
  sidebarWidth: PropTypes.string,
};
