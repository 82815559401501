import React, { useState, useEffect, createContext } from "react";
import axios from "axios";
import Cookies from "universal-cookie";

export const UserContext = createContext({});

export const UserProvider = (props) => {
  let initialLoadedState = false;
  if (props.userId && props.userId.length === 0) {
    initialLoadedState = true;
  }

  let [userContext, setUserContext] = useState({
    user: null,
    loaded: initialLoadedState,
  });

  const fetchUserInfo = async function () {
    let result;
    try {
      result = await axios.get("/api/v2/users/" + props.userId);
    } catch (err) {
      console.error(err);
    }

    let { data: companies } = await axios.get(
      "/api/v2/users/" + props.userId + "/companies"
    );

    const cookies = new Cookies();

    // let urlSearchParams;
    // let params;

    // if (window) {
    //   urlSearchParams = new URLSearchParams(window.location.search);
    //   params = Object.fromEntries(urlSearchParams.entries());

    //   if (params.set_focused_company) {
    //     cookies.set("ps_focused_company_name", params.set_focused_company, {
    //       path: "/",
    //     });
    //   }
    // }

    let focusedCompanyName = null;
    let focusedCompanyId = null;
    let focusedCompany = null;
    let focusedCompanyAccount = null;

    if (cookies.get("ps_focused_company_name")) {
      focusedCompanyName = cookies.get("ps_focused_company_name");
    }

    if (cookies.get("ps_focused_company_id")) {
      focusedCompanyId = cookies.get("ps_focused_company_id");
    }

    if (companies.length) {
      if (focusedCompanyId) {
        let matchingCompanies = companies.filter(
          (company) => company.company_id === focusedCompanyId
        );

        if (matchingCompanies.length) {
          focusedCompany = matchingCompanies[0];
        } else {
          focusedCompany = companies[0];
        }
      } else {
        focusedCompany = companies[0];
      }

      let focusedCompanyAccountResult = await axios.get(
        "/api/v2/companies/" + focusedCompany.company_id + "/account"
      );

      focusedCompanyAccount = focusedCompanyAccountResult.data;
    }

    let portfoliosUserHasAdminAccessTo = [];

    try {
      let { data: portfoliosUserHasAdminAccessToResult } = await axios.get(
        "/api/v2/users/" + props.userId + "/portfolios"
      );

      if (portfoliosUserHasAdminAccessToResult.length) {
        portfoliosUserHasAdminAccessTo = portfoliosUserHasAdminAccessToResult;
      }
    } catch (err) {
      console.error(err.message);
    }

    let userContext = {
      user: result.data,
      focusedCompany: focusedCompany,
      focusedCompanyAccount: focusedCompanyAccount,
      companiesUserHasAdminAccessTo: companies,
      portfoliosUserHasAdminAccessTo: portfoliosUserHasAdminAccessTo,
      loaded: true,
    };

    setUserContext(userContext);
  };

  useEffect(() => {
    if (props.userId) {
      fetchUserInfo();
    } else {
      let userContext = {
        user: null,
        loaded: true,
      };

      setUserContext(userContext);
    }
  }, [props.userId]);

  return (
    <UserContext.Provider value={[userContext, setUserContext]}>
      {props.children}
    </UserContext.Provider>
  );
};

export const UserConsumer = UserContext.Consumer;
