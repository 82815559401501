import React from "react";
import PropTypes from "prop-types";

import "ConfirmationDialog/styles.scss";

import { Modal, Button } from "react-bootstrap";

export const ConfirmationDialog = ({
  proceedLabel,
  cancelLabel,
  title,
  confirmationText,
  show,
  onProceed,
  onCancel,
  additionalCloseBehavior,
  additionalOnProceedBehavior,
  variant,
}) => {
  if(!additionalCloseBehavior){
    additionalCloseBehavior = () => {}
  }
  if(!additionalOnProceedBehavior){
    additionalOnProceedBehavior = () => {}
  }
  return (
    <div>
      <Modal
        className="ps-confirmation-dialog"
        show={show}
        centered
        keyboard
        animation={false}
      >
       <div className="ps-confirmation-dialog__cancel-icon-container" onClick={()=>{onCancel();additionalCloseBehavior()}}>
          <img
            className="ps-confirmation-dialog__cancel-icon"
            src="/images/icons/black-x-mark.svg"
          />
        </div>
        <div className="ps-confirmation-dialog__header">{title}</div>
        <div className="ps-confirmation-dialog__body">{confirmationText}</div>
        <div className="ps-confirmation-dialog__footer">
          {variant === "danger" && (
            <React.Fragment>
              {cancelLabel &&
                   <button
                   className="btn ps-btn--white ps-btn--auto-width"
                   onClick={()=>{onCancel();additionalCloseBehavior()}}
                   autoFocus
                 >
                   {cancelLabel}
                 </button>
              }
              <button
                className="btn ps-btn--primary ps-btn--auto-width ml-3"
                onClick={()=>{onProceed();additionalOnProceedBehavior()}}
                autoFocus
              >
                {proceedLabel}
              </button>
            </React.Fragment>
          )}

          {variant === "info" && (
            <Button color="primary" onClick={()=>{onCancel();additionalCloseBehavior()}}>
              {proceedLabel}
            </Button>
          )}

        </div>
      </Modal>
    </div>
  );
};


ConfirmationDialog.propTypes = {
  proceedLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  confirmationText: PropTypes.string,
  show: PropTypes.bool,
  onProceed: PropTypes.func,
  dismiss: PropTypes.bool,
  onCancel: PropTypes.func,
  additionalCloseBehavior: PropTypes.func,
  additionalOnProceedBehavior: PropTypes.func,
  variant: PropTypes.string,
  enableEscape: PropTypes.bool
};
