import React, { useContext } from "react";
import { UserContext } from "UserContext";
import SelectSearchDropdown from "SelectSearchDropdown";
import Cookies from "universal-cookie";
import NewUpdateDropdownButton from "NewUpdateDropdownButton";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import {
  Router,
  Link,
  useLocation,
  useNavigate,
  globalHistory,
} from "@reach/router";

const GlobalSideNav = ({
  isSidebarExpanded,
  setIsSidebarExpanded,
  activePage,
  setActivePage,
}) => {
  const [userContext, dispatchUserContext] = useContext(UserContext);

  const cookies = new Cookies();
  const navigate = useNavigate();
  const location = useLocation();

  const NavItem = ({
    pageName,
    linkPath,
    titleText,
    icon,
    collapseOnClick,
  }) => {
    return (
      <React.Fragment>
        <OverlayTrigger
          key={pageName}
          placement="right"
          delay={{ show: 50, hide: 150 }}
          overlay={
            isSidebarExpanded ? (
              <React.Fragment></React.Fragment>
            ) : (
              <Tooltip>
                <div style={{ textAlign: "left" }}>{titleText}</div>
              </Tooltip>
            )
          }
        >
          <Link to={linkPath}>
            <div
              className={`ps-global-side-nav__nav-item ${
                isSidebarExpanded
                  ? ""
                  : "ps-global-side-nav__nav-item--collapsed"
              } ${
                activePage === pageName
                  ? "ps-global-side-nav__nav-item--active-page"
                  : ""
              }`}
              onClick={() => {
                setActivePage(pageName);
                if (collapseOnClick) {
                  setIsSidebarExpanded(false);
                }
              }}
            >
              {icon}
              <div
                className={`ps-global-side-nav__nav-item-text ${
                  isSidebarExpanded
                    ? ""
                    : "ps-global-side-nav__nav-item-text--collapsed"
                }`}
              >
                {titleText}
              </div>
            </div>
          </Link>
        </OverlayTrigger>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div
        className="ps-global-side-nav__mobile-hamburger-menu"
        onClick={() => setIsSidebarExpanded(!isSidebarExpanded)}
      >
        <img src="/images/icons/hamburger-menu-black-icon.svg" alt="" />
      </div>
      <div
        className={`ps-global-side-nav__container ${
          isSidebarExpanded ? "" : "ps-global-side-nav__container--collapsed"
        }`}
      >
        {/* {userContext.user === null && <div>loggedout</div>} */}

        <React.Fragment>
          <div
            className={`ps-global-side-nav__heading-container ${
              isSidebarExpanded
                ? ""
                : "ps-global-side-nav__heading-container--collapsed"
            }`}
          >
            <div
              className={`ps-global-side-nav__focused-company-container ${
                isSidebarExpanded
                  ? ""
                  : `ps-global-side-nav__focused-company-container--hide`
              }`}
            >
              {userContext.companiesUserHasAdminAccessTo &&
                userContext.companiesUserHasAdminAccessTo.length > 0 && (
                  <SelectSearchDropdown
                    companies={userContext.companiesUserHasAdminAccessTo}
                    companyDisplayName={userContext.focusedCompany.display_name}
                    companyLogoUrl={userContext.focusedCompany.logo_url}
                    onCompanySelect={async (selectedCompany) => {
                      // console.log("selected company :>> ", selectedCompany);

                      userContext.focusedCompany = selectedCompany;

                      //get company account
                      let focusedCompanyAccountResult;
                      try {
                        focusedCompanyAccountResult = await axios.get(
                          "/api/v2/companies/" +
                            selectedCompany.company_id +
                            "/account"
                        );
                      } catch (err) {
                        console.error(err);
                      }

                      // console.log(
                      //   "focusedCompanyAccountResult.data :>> ",
                      //   focusedCompanyAccountResult.data
                      // );

                      userContext.focusedCompanyAccount =
                        focusedCompanyAccountResult.data;

                      cookies.set(
                        "ps_focused_company_id",
                        selectedCompany.company_id,
                        {
                          path: "/",
                        }
                      );

                      dispatchUserContext(userContext);
                      navigate("/home");
                    }}
                  />
                )}

              {userContext.companiesUserHasAdminAccessTo &&
                userContext.companiesUserHasAdminAccessTo.length === 0 && (
                  <div>
                    <a
                      className="btn ps-btn--primary"
                      style={{ marginLeft: "20px" }}
                      href="/onboarding/company"
                    >
                      Create Company
                    </a>
                  </div>
                )}
            </div>

            <div
              onClick={() => {
                setIsSidebarExpanded(!isSidebarExpanded);
              }}
              className="ps-global-side-nav__expand-collapse-icon-container"
            >
              <img
                src="/images/icons/chevrons-left-gray-icon.svg"
                className={`ps-global-side-nav__expand-collapse-icon ${
                  isSidebarExpanded
                    ? ""
                    : "ps-global-side-nav__expand-collapse-icon--collapsed"
                }`}
              />
            </div>
          </div>
          <div
            className={`ps-global-side-nav__lower-nav-container ${
              isSidebarExpanded
                ? ""
                : "ps-global-side-nav__lower-nav-container--collapsed"
            }`}
          >
            {userContext.focusedCompany !== undefined &&
              userContext.focusedCompany !== null && (
                <NewUpdateDropdownButton
                  companyName={userContext.focusedCompany.name}
                  companyId={userContext.focusedCompany.company_id}
                  buttonStyle={{
                    width: "100%",
                    marginBottom: "20px",
                    height: "36px",
                    padding: `${isSidebarExpanded ? "" : "8px"}`,
                  }}
                  showIconOnly={!isSidebarExpanded}
                />
              )}
            {/* {userContext.focusedCompany !== undefined &&
              userContext.focusedCompany !== null && ( */}
            <div className={`ps-global-side-nav__nav-items-list-container`}>
              <React.Fragment>
                <NavItem
                  pageName={"home"}
                  linkPath={"home"}
                  titleText="Home"
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className={`feather feather-home ps-global-side-nav__nav-item-icon ${
                        activePage === "home"
                          ? "ps-global-side-nav__nav-item-icon-svg--active"
                          : ""
                      }`}
                    >
                      <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                      <polyline points="9 22 9 12 15 12 15 22"></polyline>
                    </svg>
                  }
                />

                {userContext.focusedCompany !== undefined &&
                  userContext.focusedCompany !== null && (
                    <React.Fragment>
                      <NavItem
                        pageName={"profile"}
                        linkPath={`${userContext.focusedCompany.name}`}
                        titleText="Profile"
                        icon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className={`feather feather-home ps-global-side-nav__nav-item-icon ${
                              activePage === "profile"
                                ? "ps-global-side-nav__nav-item-icon-svg--active"
                                : ""
                            }`}
                          >
                            <rect
                              x="3"
                              y="3"
                              width="18"
                              height="18"
                              rx="2"
                              ry="2"
                            ></rect>
                            <line x1="3" y1="9" x2="21" y2="9"></line>
                            <line x1="9" y1="21" x2="9" y2="9"></line>
                          </svg>
                        }
                      />

                      <NavItem
                        pageName={"updates"}
                        linkPath={`${userContext.focusedCompany.name}/updates`}
                        titleText="Updates"
                        collapseOnClick={true}
                        icon={
                          <svg
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className={`feather feather-home ps-global-side-nav__nav-item-icon ${
                              activePage === "updates"
                                ? "ps-global-side-nav__nav-item-icon-svg--active"
                                : ""
                            }`}
                          >
                            <path
                              d="M10.6667 2.5L14 5.83333L5.33333 14.5H2V11.1667L10.6667 2.5Z"
                              stroke="#40434A"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        }
                      />

                      <NavItem
                        pageName={"contacts"}
                        linkPath={`${userContext.focusedCompany.name}/contacts`}
                        titleText="Contacts"
                        collapseOnClick={true}
                        icon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className={`feather feather-home ps-global-side-nav__nav-item-icon ${
                              activePage === "contacts"
                                ? "ps-global-side-nav__nav-item-icon-svg--active"
                                : ""
                            }`}
                          >
                            <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                            <circle cx="9" cy="7" r="4"></circle>
                            <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                            <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                          </svg>
                        }
                      />

                      {userContext.portfoliosUserHasAdminAccessTo &&
                        userContext.portfoliosUserHasAdminAccessTo.length >
                          0 && (
                          <NavItem
                            pageName={"portfolios"}
                            linkPath={`portfolios`}
                            titleText="Portfolios"
                            collapseOnClick={true}
                            icon={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className={`feather feather-home ps-global-side-nav__nav-item-icon ${
                                  activePage === "portfolios"
                                    ? "ps-global-side-nav__nav-item-icon-svg--active"
                                    : ""
                                }`}
                              >
                                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
                              </svg>
                            }
                          />
                        )}
                    </React.Fragment>
                  )}
              </React.Fragment>
            </div>

            {userContext.user === null && userContext.loaded === true && (
              <React.Fragment>
                {isSidebarExpanded === true && (
                  <React.Fragment>
                    <div style={{ margin: "15px 0" }}>
                      Signup or login to discover and track companies on
                      Paperstreet.
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                      <a className="btn ps-btn--primary" href="/signup">
                        Signup
                      </a>
                    </div>
                    <div>
                      <a className="btn ps-btn--outline" href="/login">
                        Login
                      </a>
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>
        </React.Fragment>
      </div>
    </React.Fragment>
  );
};

GlobalSideNav.propTypes = {};

export default GlobalSideNav;

// const LoggedOutNav = props => {
//   return (
//     <div>
//       LoggedOut
//     </div>
//   );
// };

// GlobalSideNav.propTypes = {

// };
