/* eslint-disable react/prop-types */

import * as React from "react";
import { ConfirmationDialog } from "./ConfirmationDialog";

const ConfirmationServiceContext = React.createContext(Promise.reject);

export const useConfirmation = () =>
  React.useContext(ConfirmationServiceContext);


export const ConfirmationServiceProvider = ({ children }) => {

  const [confirmationState, setConfirmationState] = React.useState(null);

  const awaitingPromiseRef = React.useRef();


  let options = {
    variant: "info", //"danger" | "info"
    title: "",
    description: ""
  }


  const openConfirmation = (options) => {
    setConfirmationState(options);

    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const onCancel = () => {
    if (confirmationState.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }

    setConfirmationState(null);
  };

  const onProceed = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }

    setConfirmationState(null);
  };

  return (
    <div>
      <ConfirmationServiceContext.Provider value={openConfirmation}>
        {children}
      </ConfirmationServiceContext.Provider>
      
      <ConfirmationDialog
        show={Boolean(confirmationState)}
        onProceed={onProceed}
        onCancel={onCancel}
        {...confirmationState}
      />
    </div>
  );
};
