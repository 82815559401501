/* eslint-disable react/display-name */
import React from "react";
import { Dropdown } from "react-bootstrap/";
import { Link, useNavigate } from "@reach/router";
import axios from "axios";
import { toast } from "ToastAlertContainer";
import "./styles.scss";

const NewUpdateDropdownButton = (props) => {
  const { companyName } = props;
  const navigate = useNavigate();
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
      href=""
      className={"btn ps-btn--primary dropdown-toggle"}
      ref={ref}
      style={props.buttonStyle ? props.buttonStyle : null}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </button>
  ));
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle}>
        {props.showIconOnly === true && (
          <img src="/images/icons/pencil-edit-outline-white-icon.svg" />
        )}
        {!props.showIconOnly && <React.Fragment>Compose ▾</React.Fragment>}
      </Dropdown.Toggle>

      <Dropdown.Menu className="ps-new-update-dropdown-button__menu">
        <Dropdown.Item style={{padding: "0px"}}>
          <div
            className="dropdown-item ps-new-update-dropdown-button__item ps-new-update-dropdown-button__item--compose"
            // to={`${companyName}/open-latest-draft`}
            data-tracking-id="compose-private-update-button"
            onClick={async () => {
              let draftsResult;
              try {
                draftsResult = await axios.get(
                  "/api/v2/companies/" +
                    props.companyId +
                    "/posts?published_state=draft&offset=0&limit=10&sort_by=timestamp_edited&order_by=desc"
                );
              } catch (err) {
                console.error(err.response.data);
              }

              if (
                draftsResult.data &&
                draftsResult.data.data &&
                draftsResult.data.data.length > 0
              ) {
                let recentDraft = draftsResult.data.data[0];

                navigate(
                  "/" + companyName + "/composer/" + recentDraft.post_id
                );
              } else {
                toast.warning("No drafts available to edit");
              }
            }}
          >
            <div>
              <div className="ps-new-update-dropdown-button__text-container ps-new-update-dropdown-button__text-container--continue-editing-icon">
                <span className="ps-new-update-dropdown-button__text">
                  Continue Editing
                </span>
                <span className="ps-new-update-dropdown-button__sub-text">
                  <br />
                  Open your most recent draft
                </span>
              </div>
            </div>
          </div>
        </Dropdown.Item>

        <hr className="ps-new-update-dropdown-button__hr" />

        <Dropdown.Item style={{padding: "0px"}}>
          <div
            className="dropdown-item ps-new-update-dropdown-button__item ps-new-update-dropdown-button__item--compose"
            to={`${companyName}/composer`}
            data-tracking-id="compose-private-update-button"
            onClick={async () => {
              let newPostResult = await axios.post(
                "/api/v2/companies/" + props.companyId + "/posts"
              );

              try {
                navigate(
                  "/" + companyName + "/composer/" + newPostResult.data.post_id
                );
                toast.success("Created new draft");

                let eventProperties = {
                  companyName: companyName,
                  template: "default investor template",
                };
                PSBase.analytics.logAmplitudeEvent(
                  "updates: started new update from template",
                  eventProperties
                );
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <div>
              <div className="ps-new-update-dropdown-button__text-container ps-new-update-dropdown-button__text-container--investor-update-icon">
                <span className="ps-new-update-dropdown-button__text">
                  New Investor Update
                </span>
                <span className="ps-new-update-dropdown-button__sub-text">
                  <br />
                  Start an update using the default template
                </span>
              </div>
            </div>
          </div>
        </Dropdown.Item>
        <Dropdown.Item style={{padding: "0px"}}>
          <div
            className="dropdown-item ps-new-update-dropdown-button__item ps-new-update-dropdown-button__item--compose"
            to={`${companyName}/composer?template=blank`}
            data-tracking-id="compose-private-update-button"
            onClick={async () => {
              try {
                let newPostResult = await axios.post(
                  "/api/v2/companies/" +
                    props.companyId +
                    "/posts?template=blank"
                );

                navigate(
                  "/" + companyName + "/composer/" + newPostResult.data.post_id
                );
                toast.success("Created new draft");

                let eventProperties = {
                  companyName: companyName,
                  template: "blank",
                };
                PSBase.analytics.logAmplitudeEvent(
                  "updates: started new update from template",
                  eventProperties
                );
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <div>
              <div className="ps-new-update-dropdown-button__text-container ps-new-update-dropdown-button__text-container--blank-update-icon">
                <span className="ps-new-update-dropdown-button__text">
                  New Blank Update
                </span>
                <span className="ps-new-update-dropdown-button__sub-text">
                  <br />
                  Start an update from scratch
                </span>
              </div>
            </div>
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NewUpdateDropdownButton;
